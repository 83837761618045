import styled from 'styled-components';
import FloatButton from 'antd/lib/float-button';

import { sizes, media } from 'styles/variables.module';

export const MainLayoutStyleWrapper = styled.div`
  display: grid;
  gap: ${sizes.sp6xl};
`;

export const MainStyleWrapper = styled.div`
  margin-top: 80px;
  overflow: hidden;
  min-height: 50vh;
  &[data-main='true'] {
    padding: 0 ${sizes.spm};
  }

  &[data-main='false'] {
    padding: ${sizes.sps} ${sizes.spXl} 0 ${sizes.spXl};
  }

  &[data-checkout-page='true'] {
    min-height: 100vh;
  }

  ${media.sm(` 
    position: relative;
    margin-top: 110px;
    padding: 0 ${sizes.spm} ${sizes.spm};
    
    &[data-is-show-project-switcher='false'] {
      margin-top: 64px;
    }
    &[data-checkout-page='true'] {
      margin-top: 64px;
    }
  `)}
`;

export const FloatButtonStyle = styled(FloatButton.BackTop)`
  inset-inline-end: 92px;
  bottom: 35px;
`;
